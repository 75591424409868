import API_LINKS from "@/view/pages/painel/automacao/api.links";
import ApiService from "@/core/services/api.service";

export default class Utils {
  static setInterval(funcao, timeout) {
    return setInterval(funcao, timeout);
  }

  /**
   * Retorna o link do webhook da campanha.
   * @param campanha - Campanha a ser usada para pegar o token do webhook.
   * @returns {string} - Link do webhook da campanha.
   */
  static getLinkWebhookCampanha(campanha) {
    if (!campanha.webhook || !campanha.webhook.mdw_token) {
      return "";
    }
    return (
      process.env.VUE_APP_COMUNICANTE_API +
      API_LINKS.campanhaWebhook +
      "?token=" +
      (campanha ? campanha.webhook.mdw_token : "")
    );
  }

  /**
   * Retorna os recursos ativos de uma conta.
   * @param contaId - Id da conta a ser usada para pegar os recursos ativos. Caso seja null,
   * retorna os recursos ativos da conta do usuário logado.
   * @returns {Promise<unknown>}
   */
  static getRecursosByConta(contaId = null) {
    const path = contaId
      ? API_LINKS.recursosAtivos + "/" + contaId
      : API_LINKS.recursosAtivos;
    return ApiService.get(path, "", true);
  }

  /**
   * Formata a data de timestamp para o formato brasileiro.
   * @param timestamp - Data de timestamp a ser formatada.
   * @returns {string} - Data formatada.
   */
  static formateDateTimestampToBr(timestamp) {
    if (!timestamp) {
      return "";
    }
    const date = new Date(timestamp);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
  }

  /**
   * Retorna a mensagem de erro da response.
   * @param response - Response a ser usada para pegar a mensagem de erro.
   * @returns {*|string} - Mensagem de erro da response.
   */
  static formatResponseMessageToHTML(response) {
    if (
      response &&
      typeof response === "object" &&
      response.status &&
      response.data
    ) {
      const { status, data, message, error_code } = response.data;

      // Define a classe de estilo com base no status
      let alertClass = "alert-warning"; // Default para aviso
      if (status === "success") alertClass = "alert-success";
      if (status === "fail") alertClass = "alert-danger";

      // Monta o conteúdo da mensagem
      let content = `
      ${message ? `<p><strong>Mensagem:</strong> ${message}</p>` : ""}
      ${data ? `<p><strong>Detalhes:</strong> ${data}</p>` : ""}
      ${
        error_code
          ? `<p><strong>Código do erro:</strong> ${error_code}</p>`
          : ""
      }
    `;

      // Retorna o HTML com classes Bootstrap
      return `
      <div class="response-message alert ${alertClass}">
        ${content}
      </div>
    `;
    }

    // Retorno padrão para resposta inválida
    return `
    <div class="response-message alert alert-warning">
      <i>Formato de resposta inválido ou não suportado.</i>
    </div>
  `;
  }

  /**
   * Retorna os filtros obrigatórios formatados do tipo do modelo filtro.
   * @param filtrosObrigatorios - Filtros obrigatórios a serem formatados.
   * @returns {*}
   */
  static formataFiltrosObrigatoriosParaFiltros(filtrosObrigatorios) {
    return filtrosObrigatorios.map((filtro) => {
      return this.formataFiltroObrigatorioParaFiltro(filtro);
    });
  }

  /**
   * Formata e organiza filtros obrigatórios e adicionais num array estruturado.
   * @param {Array} filtros - Array original de filtros existentes.
   * @param {Array|null} filtrosObrigatorios - Array dos filtros obrigatórios.
   * @returns {Array} - Array de filtros formatados.
   */
  static formataFiltros(filtros, filtrosObrigatorios = []) {
    if (
      !(Array.isArray(filtrosObrigatorios) && filtrosObrigatorios.length > 0)
    ) {
      // Removendo filtros obrigatórios herdados
      this.removerIdFiltroObrigatorio(filtros);
      return filtros;
    }

    const filtroObrigatorioFormatado = this.recursivoFormatarBanco2Front(
      this.formataFiltrosObrigatoriosParaFiltros(filtrosObrigatorios),
      0,
      0
    ).filtroFormatado[0];

    if (this.verificaFiltrosObrigatorios(filtros, filtrosObrigatorios)) {
      // Atribuindo para obter o id do filtro obrigatório, se removido anteriormente.
      filtros[0] = filtroObrigatorioFormatado;
      return filtros;
    }

    const filtrosAdicionais = filtros.length ? [...filtros] : [];

    if (filtrosAdicionais.length > 0 && filtrosAdicionais[0].hierarquia === 0) {
      this.alterarHierarquiaFiltros(filtrosAdicionais, 1);
    } else {
      return [filtroObrigatorioFormatado];
    }
    this.removerIdFiltroObrigatorio(filtrosAdicionais);

    return [
      filtroObrigatorioFormatado,
      {
        hierarquia: 0,
        listaDeFiltros: filtrosAdicionais
      }
    ];
  }

  /**
   * Verifica se os filtros obrigatórios estão presentes nos filtros.
   * @param filtros - Filtros a serem usados para verificar os filtros obrigatórios.
   * @param filtrosObrigatorios - Filtros obrigatórios a serem verificados.
   * @return {boolean} - True se os filtros obrigatórios estiverem presentes; caso contrário, false.
   */
  static verificaFiltrosObrigatorios(filtros, filtrosObrigatorios) {
    if (Array.isArray(filtrosObrigatorios) && filtrosObrigatorios.length > 0) {
      const filtroObrigatorioFormatado = this.recursivoFormatarBanco2Front(
        this.formataFiltrosObrigatoriosParaFiltros(filtrosObrigatorios),
        0,
        0
      ).filtroFormatado[0];

      const primeiroFiltroAtual = filtros[0];

      return (
        primeiroFiltroAtual &&
        this.compareFilters(
          {
            hierarquia: primeiroFiltroAtual.hierarquia,
            listaDeFiltros: primeiroFiltroAtual.listaDeFiltros
          },
          {
            hierarquia: filtroObrigatorioFormatado.hierarquia,
            listaDeFiltros: filtroObrigatorioFormatado.listaDeFiltros
          }
        )
      );
    }
  }

  /**
   * Inicializa os filtros com base nos filtros obrigatórios.
   * @param filtros - Filtros a serem inicializados.
   * @param filtrosObrigatorios - Filtros obrigatórios a serem usados para inicializar os filtros.
   * @returns {*}
   */
  static inicializaFiltros(filtros, filtrosObrigatorios = null) {
    if (filtrosObrigatorios) {
      return filtrosObrigatorios.map((filtro) => {
        return this.formataFiltroObrigatorioParaFiltro(filtro);
      });
    }
    return filtros;
  }

  /**
   * Formata o filtro obrigatório para o filtro do tipo do modelo filtro.
   * @param filtroObrigatorio - Filtro obrigatório a ser formatado.
   * @returns {{hierarquia: *, listaDeFiltros: [], modelo_filtro_id: *, modelo_filtro_obrigatorio_id: *, operador_id: *, ope_titulo: *, ope_sinal: *, filtro_id: *, mfi_valor: *, mfi_parenteses_inicio: *, mfi_parenteses_fim: *, mfi_e_ou: *, mfi_valor_2: *, fil_tipo: *, fil_titulo: *, fil_campo: *, fil_descricao: *, fil_conteudo: *, fil_mascara: *, fil_tipo_campo: *, fil_tipo_dado: *, fil_mascara_formato: *, fil_msg_chatbot: *, contexto_id: *, con_titulo: *, con_mascara: *, con_grupo: *, con_modificador: *, mfo_is_editable: *, mfo_is_hidden: *}}
   */
  static formataFiltroObrigatorioParaFiltro(filtroObrigatorio) {
    return {
      listaDeFiltros: [],
      modelo_filtro_id: filtroObrigatorio.mfo_modelo_filtro_id,
      modelo_filtro_obrigatorio_id: filtroObrigatorio.mfo_id,
      operador_id: filtroObrigatorio.mfo_operador_id,
      ope_titulo: filtroObrigatorio.operador.ope_titulo,
      ope_sinal: filtroObrigatorio.operador.ope_sinal,
      filtro_id: filtroObrigatorio.mfo_filtro_id,
      mfi_valor: filtroObrigatorio.mfo_valor,
      mfi_parenteses_inicio: filtroObrigatorio.mfo_parenteses_inicio,
      mfi_parenteses_fim: filtroObrigatorio.mfo_parenteses_fim,
      mfi_e_ou: filtroObrigatorio.mfo_e_ou,
      mfi_valor_2: filtroObrigatorio.mfo_valor_2,
      fil_tipo: filtroObrigatorio.filtro.fil_tipo,
      fil_titulo: filtroObrigatorio.filtro.fil_titulo,
      fil_campo: filtroObrigatorio.filtro.fil_campo,
      fil_descricao: filtroObrigatorio.filtro.fil_descricao,
      fil_conteudo: filtroObrigatorio.filtro.fil_conteudo,
      fil_mascara: filtroObrigatorio.filtro.fil_mascara,
      fil_tipo_campo: filtroObrigatorio.filtro.fil_campo,
      fil_tipo_dado: filtroObrigatorio.filtro.fil_tipo_dado,
      fil_mascara_formato: filtroObrigatorio.filtro.fil_mascara_formato,
      fil_msg_chatbot: filtroObrigatorio.filtro.fil_msg_chatbot,
      contexto_id: filtroObrigatorio.mfo_contexto_funcao_id,
      con_titulo: filtroObrigatorio.contexto_funcao?.con_titulo ?? null,
      con_mascara: filtroObrigatorio.contexto_funcao?.con_mascara ?? null,
      con_grupo: filtroObrigatorio.contexto_funcao?.con_grupo ?? null,
      con_modificador:
        filtroObrigatorio.contexto_funcao?.con_modificador ?? null,
      mfo_is_editable: filtroObrigatorio.mfo_is_editable,
      mfo_is_hidden: filtroObrigatorio.mfo_is_hidden
    };
  }

  /**
   * Adiciona um novo filtro padrão na lista existente de filtros.
   *
   * @param {Array} filtros - Lista original de filtros.
   * @param {Array|null} filtrosObrigatorios - Lista de filtros obrigatórios (opcional).
   * @returns {Array} Nova lista de filtros com o filtro padrão adicionado.
   */
  static addNovoFiltro(filtros, filtrosObrigatorios = []) {
    const temFiltrosObg =
      Array.isArray(filtrosObrigatorios) && filtrosObrigatorios.length > 0;
    const novoFiltroPadrao = {
      hierarquia: temFiltrosObg ? 1 : 0,
      filtro_id: 1,
      mfi_valor: "",
      ope_titulo: "",
      mfi_e_ou: "AND",
      mfi_valor_2: null,
      operador_id: 12,
      listaDeFiltros: [],
      contexto_id: null
    };

    if (temFiltrosObg) {
      const filtrosFormatados = this.formataFiltros(
        filtros,
        filtrosObrigatorios
      );

      if (!filtrosFormatados[1]) {
        filtrosFormatados[1] = { hierarquia: 0, listaDeFiltros: [] };
      }

      filtrosFormatados[1].listaDeFiltros.push(novoFiltroPadrao);

      return filtrosFormatados;
    }

    return [...filtros, novoFiltroPadrao];
  }

  /**
   * Altera a hierarquia recursivamente dos filtros.
   * @param filtros - Filtros a serem usados para alterar a hierarquia.
   * @param hierarquia - Hierarquia a ser incrementada nos filtros.
   * @return {*}
   */
  static alterarHierarquiaFiltros(filtros, hierarquia) {
    for (const filtro of filtros) {
      filtro.hierarquia = hierarquia;
      if (filtro.listaDeFiltros && filtro.listaDeFiltros.length > 0) {
        this.alterarHierarquiaFiltros(filtro.listaDeFiltros, hierarquia + 1);
      }
    }
    return filtros;
  }

  /**
   * Remove o id do filtro obrigatório dos filtros.
   * @param filtros - Filtros a serem usados para remover o id do filtro obrigatório.
   */
  static removerIdFiltroObrigatorio(filtros) {
    for (const filtro of filtros) {
      delete filtro.modelo_filtro_obrigatorio_id;
      if (filtro.listaDeFiltros && filtro.listaDeFiltros.length > 0) {
        this.removerIdFiltroObrigatorio(filtro.listaDeFiltros);
      }
    }
  }

  /**
   * Format the filters, transforming from the database format to the front-end format.
   *
   * @param {Array} filtros - The array of filter objects.
   * @param {number} posicao - The current index in the filtros array.
   * @param {number} hierarquia - The current hierarchy level.
   * @returns {object} - An object containing:
   *   - filtroFormatado: the formatted filters,
   *   - posicao: the current index,
   *   - hierarquia: the updated hierarchy level.
   */
  /**
   * Format the filters, transforming from the database format to the front-end format.
   *
   * @param {Array} filtros - The array of filter objects.
   * @param {number} posicao - The current index in the filtros array.
   * @param {number} hierarquia - The current hierarchy level.
   * @returns {object} - An object containing:
   *   - filtroFormatado: the formatted filters,
   *   - posicao: the updated index,
   *   - hierarquia: the updated hierarchy level.
   */
  static recursivoFormatarBanco2Front(filtros, posicao, hierarquia) {
    const filtroFormatado = [];
    const size = filtros.length;

    while (posicao < size) {
      let filtro = {
        hierarquia,
        listaDeFiltros: []
      };

      if (filtros[posicao]["mfi_parenteses_inicio"]) {
        filtros[posicao]["mfi_parenteses_inicio"] =
          filtros[posicao]["mfi_parenteses_inicio"].substring(1);
        hierarquia++;

        const recResult = this.recursivoFormatarBanco2Front(
          filtros,
          posicao,
          hierarquia
        );

        filtro.listaDeFiltros = recResult.filtroFormatado;
        posicao = recResult.posicao; // Updated position from recursion
        hierarquia = recResult.hierarquia;
      } else {
        filtro = { ...filtro, ...filtros[posicao] };
      }

      filtroFormatado.push(filtro);

      if (filtros[posicao]["mfi_parenteses_fim"]) {
        filtros[posicao]["mfi_parenteses_fim"] =
          filtros[posicao]["mfi_parenteses_fim"].substring(1);
        hierarquia--;
        return { filtroFormatado, posicao, hierarquia };
      }

      posicao++; // Only increment after processing each filter completely
    }

    return { filtroFormatado, posicao, hierarquia };
  }

  /**
   * Compares two filters objects.
   *
   * The filters objects are expected to be simple objects where each property represents a filter.
   * The function first compares the keys (i.e. filter identifiers) and then compares the individual filters
   * using compareFilterObjects.
   *
   * @param {object} filtersObj1 - The first filters object.
   * @param {object} filtersObj2 - The second filters object.
   * @returns {boolean} - True if all filters match for the specified fields; otherwise, false.
   */
  static compareFilters(filtersObj1, filtersObj2) {
    const fieldsToCompare = [
      "hierarquia",
      "listaDeFiltros",
      "modelo_filtro_obrigatorio_id",
      "operador_id",
      "filtro_id",
      "contexto_id"
    ];

    if (filtersObj1 === filtersObj2) return true;

    if (
      typeof filtersObj1 !== "object" ||
      filtersObj1 === null ||
      typeof filtersObj2 !== "object" ||
      filtersObj2 === null
    ) {
      return false;
    }

    const isArray = Array.isArray(filtersObj1) && Array.isArray(filtersObj2);
    if (isArray && filtersObj1.length !== filtersObj2.length) {
      return false;
    }

    const keysA = Object.keys(filtersObj1);
    const keysB = Object.keys(filtersObj2);

    for (let key of keysA) {
      if (fieldsToCompare.includes(key) || isArray) {
        if (
          !keysB.includes(key) ||
          !this.compareFilters(filtersObj1[key], filtersObj2[key])
        ) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Deep search for a target field in an object.
   * @param object - Object to search for the target field.
   * @param targetField - Target field to search for in the object.
   * @return {*|null} - The target field value if found; otherwise, null.
   */
  static deepSearch(object, targetField) {
    if (typeof object !== "object" || object === null) return null;

    if (object.hasOwnProperty(targetField)) {
      return object[targetField];
    }

    for (const key in object) {
      if (typeof object[key] === "object") {
        const result = this.deepSearch(object[key], targetField);
        if (result !== null) {
          return result;
        }
      }
    }

    return null;
  }
}
